import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicSearchDialogComponent } from './topic-search-dialog/topic-search-dialog.component';
import { MaterialModule } from '../../global/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AppBuilderRoutingModule } from '../app-builder/app-builder-routing.module';
import { ModelBuilderModule } from '../model-builder/model-builder.module';
import { ApplicationModule } from '../application/application.module';
import { MatStepperModule } from '@angular/material/stepper';
import { TopicStatusDialogComponent } from './topic-status-dialog/topic-status-dialog.component';
import { DataHookConnectionDialogComponent } from './data-hook-connection-dialog/data-hook-connection-dialog.component';
import { ModelSearchDialogComponent } from './model-search-dialog/model-search-dialog.component';
import { CategorySearchDialogComponent } from './category-search-dialog/category-search-dialog.component';
import { SentimentModelSearchDialogComponent } from './sentiment-model-search-dialog/sentiment-model-search-dialog.component';
import { CustomTooltipDirective } from 'src/app/services/custom-tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { TopicInfoComponent } from './topic-info/topic-info.component';
import { DatahookInfoComponent } from './datahook-info/datahook-info.component';
import { AppCloneDialogComponent } from './app-clone-dialog/app-clone-dialog.component';

@NgModule({
  declarations: [
    TopicStatusDialogComponent,
    TopicSearchDialogComponent,
    DataHookConnectionDialogComponent,
    ModelSearchDialogComponent,
    CategorySearchDialogComponent,
    SentimentModelSearchDialogComponent,
    CustomTooltipDirective,
    TopicInfoComponent,
    DatahookInfoComponent,
    AppCloneDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    AppBuilderRoutingModule,
    ModelBuilderModule,
    MatStepperModule,
    OverlayModule,
    ApplicationModule,
  ],
  exports: [MaterialModule, CustomTooltipDirective],
  entryComponents: [TopicInfoComponent, DatahookInfoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonComponentModule {}
